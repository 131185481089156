import React, { useEffect, useState } from "react";
import Table from "../../../components/Table/Table";
import { useTranslation } from "react-i18next";
import { Typography, IconButton, Tooltip } from "@mui/material";
import moment from "moment";
import { formatIndianCurrency } from "../../../utils/currencyFormat";
import FilePresentIcon from "@mui/icons-material/FilePresent";
import { getFile, updatePaymentStatus } from "../InternalFormState";
import { TabPanel } from "./config/tableHelper";
import { Edit } from "@mui/icons-material";
import EditHistoryDialog from "./EditHistoryDialog";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";

const StatusHistory = ({ statusData, editable }) => {
  console.log("statusData", statusData);

  const { t } = useTranslation();
  const loggedInData = useSelector((state) => state.userdata.getData);
  const [isLoading, setIsLoading] = useState(false);
  const [documentId, setDocumentId] = useState("");
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [editingItem, setEditingItem] = useState(null);
  const [editableFields, setEditableFields] = useState(["comment"]);

  const handleEditClick = (item, index) => {
    setEditingItem(item);

    // Check if this is the latest status and it's "Partial Payment"
    if (index === 0 && item.currentStatus === "Partial Payment") {
      setEditableFields(["comment", "amount"]);
    } else {
      setEditableFields(["comment"]);
    }

    setEditDialogOpen(true);
  };

  const handleSave = async (editedItem) => {
    setIsLoading(true);
    try {
      const updateData = {
        documentId: documentId,
        changeId: editedItem?._id,
        comments: editedItem?.updatedComment,
      };
      if (editedItem?.updatedAmount !== undefined) {
        updateData.amountPaid = editedItem.updatedAmount;
      }
      const response = await updatePaymentStatus(updateData);
      if (response.status === "200") {
        toast.success(response.message);
      } else if (response.status === "400") {
        toast.error(
          response.message || "An error occurred while updating the status"
        );
      }
      setEditDialogOpen(false);
    } catch (error) {
      toast.error("An error occurred while updating the status");
    } finally {
      setIsLoading(false);
    }
  };

  const selectHeader = () => {
    return [
      {
        label: t("Updated at"),
        options: {
          filter: true,
        },
      },
      {
        label: t("Updated by"),
        options: {
          filter: true,
        },
      },
      {
        label: t("Status from"),
        options: {
          filter: true,
        },
      },
      {
        label: t("Status to"),
        options: {
          filter: true,
        },
      },
      {
        label: t("Additional info"),
        options: {
          filter: false,
        },
      },
      {
        label: t("Attachments"),
        options: {
          filter: false,
        },
      },
      {
        label: t("Edit"),
        options: {
          filter: false,
        },
      },
    ];
  };

  const handleDownload = (file) => {
    let body = {
      fileName: file.fileName || file.filename,
      folderName: file.folderName || file.folder || file.FolderName,
      expireLimt: 1000,
    };
    getFile(body)
      .then((response) => {
        if (response.status === "200") {
          window.open(response.reference, "_blank");
        } else {
          toast.success(response.message);
        }
      })
      .catch(() => {
        toast.error("An error occurred while downloading the file.");
      });
  };

  let statusHistory = statusData?.changeMade;

  useEffect(() => {
    setDocumentId(statusData?.documentId);
  }, [statusHistory]);

  const formatDateAndTime = (dateTime) => {
    return moment.utc(dateTime).format("DD/MM/YYYY [at] HH:mm");
  };

  var finalstatusData = [];

  const RenderAttachments = ({ attachments, handleDownload }) => {
    if (!attachments || attachments.length === 0) {
      return "N/A";
    }

    return (
      <div>
        {attachments.map((attachment, index) => (
          <Tooltip key={index} title={attachment.fileName || "Download"}>
            <IconButton
              onClick={() => handleDownload(attachment)}
              size="small"
              color="default"
            >
              <FilePresentIcon fontSize="medium" />
            </IconButton>
          </Tooltip>
        ))}
      </div>
    );
  };

  function renderAdditionalInfo(
    comments,
    transactionID,
    amountPaid,
    amountDue,
    currentStatus,
    expenseReport,
    soldDetails
  ) {
    return (
      <div>
        {currentStatus === "Sold" && soldDetails && soldDetails.length > 0 && (
          <div>
            {soldDetails.map((sale, index) => (
              <div key={sale._id} style={{ marginBottom: "10px" }}>
                <Typography variant="body1">Buyer {index + 1}:</Typography>
                <Typography variant="body2">{sale.soldTo}</Typography>
                <Typography variant="body2">
                  Sale Date: {new Date(sale.saleDate).toLocaleDateString()}
                </Typography>
                <Typography variant="body2">
                  Invoice Id: {sale.saleInvoiceId || "N/A"}
                </Typography>
                <Typography variant="body2">
                  Sale Amt: {formatIndianCurrency(sale.saleAmount)}
                </Typography>
                <Typography variant="body2">
                  Comments: {sale.saleComments || "N/A"}
                </Typography>
                {sale.attachments && sale.attachments.length > 0 && (
                  <>
                    <Typography variant="body2">Attachments:</Typography>
                    <RenderAttachments
                      attachments={sale.attachments}
                      handleDownload={handleDownload}
                    />
                  </>
                )}
              </div>
            ))}
          </div>
        )}
        {transactionID && (
          <>
            <Typography variant="p">Transaction Id: {transactionID}</Typography>
            <br />
          </>
        )}
        {amountPaid && (
          <>
            <Typography variant="p">
              Amount paid: {formatIndianCurrency(amountPaid)}
            </Typography>
            <br />
          </>
        )}
        {currentStatus === "Partial Payment" && (
          <Typography variant="p">
            Amount Due: {formatIndianCurrency(amountDue)}
          </Typography>
        )}
        {currentStatus === "Expense Report Submitted" && (
          <Typography variant="p">
            Expense utilized:{" "}
            {formatIndianCurrency(expenseReport?.totalExpense)}
          </Typography>
        )}
        {currentStatus !== "Sold" && (
          <Typography variant="p" style={{ display: "block" }}>
            Comments:{" "}
            {comments
              ? comments
              : expenseReport
                ? expenseReport.comments
                : "N/A"}
          </Typography>
        )}
      </div>
    );
  }

  return (
    <div>
      <TabPanel value={0} index={0} key={1}>
        {statusHistory?.map((item, index) => {
          finalstatusData?.push([
            item.changeDate ? formatDateAndTime(item.changeDate) : "N/A",
            item.updatedBy ? item.updatedBy?.userName : "N/A",
            item.previousStatus ? item.previousStatus : "N/A",
            item.currentStatus ? item.currentStatus : "N/A",
            renderAdditionalInfo(
              item.comments,
              item?.transactionId,
              item?.amountPaid,
              item?.amountDue,
              item.currentStatus,
              item?.expenseReport,
              item?.soldDetails
            ),
            <RenderAttachments
              attachments={item.attachments || item.soldDetails?.attachments}
              handleDownload={handleDownload}
            />,
            <IconButton
              disabled={
                item.currentStatus === "Sold" ||
                !editable ||
                loggedInData?._id !== item?.updatedBy?._id
              }
              onClick={() => handleEditClick(item, index)}
              size="small"
            >
              <Edit />
            </IconButton>,
          ]);
        })}
        <Table
          data={finalstatusData}
          header={selectHeader()}
          name={t("Status History")}
          options={{
            print: false,
            download: false,
            filter: true,
            filterType: "dropdown",
            selectableRows: "none",
            sortOrder: {
              name: t("Updated At"),
              direction: "desc",
            },
          }}
        />
      </TabPanel>

      <EditHistoryDialog
        open={editDialogOpen}
        onClose={() => setEditDialogOpen(false)}
        item={editingItem}
        documentId={documentId}
        onSave={handleSave}
        editableFields={editableFields}
        isLoading={isLoading}
      />
    </div>
  );
};

export default StatusHistory;
