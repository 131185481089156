import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";

import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  Grid,
  Button,
  TextField,
  MenuItem,
  Select,
  InputLabel,
  CircularProgress,
  FormControl,
  Typography,
  Paper,
  FormHelperText,
  Alert,
  Divider,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import SendIcon from "@mui/icons-material/Send";
import { NumberFormatCustom } from "../../../utils/currencyFormat";

import UploadFilesImages from "../components/UploadFilesImages";

import { createDDRequest } from "../InternalFormState";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";

// Validation schema
const schema = yup.object().shape({
  type: yup.string().required("DD Type is required"),
  amount: yup
    .number()
    .transform((value) => (isNaN(value) ? undefined : value))
    .required("Amount is required")
    .positive("Amount must be positive"),
  paymentFrom: yup.string().required("Payment From is required"),
  date: yup
    .date()
    .min(new Date(), "Date cannot be in the past")
    .required("Required By Date is required"),
  paymentDetails: yup.object({
    accountHolderName: yup
      .string()
      .matches(/^[A-Za-z\s]+$/, "Only alphabets are allowed")
      .required("Account Holder Name is required"),
    bankName: yup
      .string()
      .matches(/^[A-Za-z\s]+$/, "Only alphabets are allowed")
      .required("Bank Name is required"),
    accountNumber: yup.string().required("Account Number is required"),
    ifscCode: yup
      .string()
      .length(11, "IFSC Code must be 11 characters")
      .required("IFSC Code is required"),
  }),
  contactPerson: yup.object({
    company: yup.string().required("Company is required"),
    name: yup.string().required("Name is required"),
    email: yup
      .string()
      .email("Invalid email format")
      .required("Email is required"),
    phone: yup
      .string()
      .matches(/^[0-9]{10}$/, "Phone number must be 10 digits")
      .nullable()
      .transform((value) => (value === "" ? null : value))
      .optional(),
  }),
  purposeOfDD: yup.string().required("Purpose is required"),
});

export default function DDRequestForm({ toggleView }) {
  const userData = useSelector((state) => state.userdata.getData);
  const [loader, setLoader] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [uploadingFile, setUploadingFile] = useState(false);

  const handleUploadedFiles = (files) => {
    const filteredFiles = files.filter((item) => Object.keys(item).length > 0);
    const filesWithoutRef = filteredFiles.map((item) => {
      const { ref, ...newItem } = item;
      return newItem;
    });
    setUploadedFiles(filesWithoutRef);
  };

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      type: "",
      amount: "",
      paymentFrom: "",
      date: dayjs(new Date()),
      paymentDetails: {
        accountHolderName: "",
        bankName: "",
        accountNumber: "",
        ifscCode: "",
      },
      contactPerson: {
        company: "",
        name: "",
        email: "",
        phone: "",
      },
      purposeOfDD: "",
    },
  });

  const ddTypes = ["EMD", "Purchase of tender documents"];
  const paymentFromOptions = [
    "Binbag Recycling",
    "Binbag Environmental",
    "UGT",
  ];

  const onSubmit = (data) => {
    console.log(data);
    
    setLoader(true);

    const updateData = {
      ...data,
      raisedBy: userData?._id,
      attachments: uploadedFiles,
    };
    createDDRequest(updateData)
      .then(() => {
        toast.success("Successfully submitted!");
        setTimeout(() => {
          toggleView(false);
          setLoader(false);
        }, 2000);
      })
      .catch((error) => {
        toast.error(error.message);
        setLoader(false);
      });
  };

  return (
    <Paper
      elevation={3}
      sx={{ p: { xs: 1, md: 4 }, maxWidth: 800, mx: "auto" }}
    >
      <Typography variant="h5" sx={{ mb: 2 }}>
        Demand draft
      </Typography>

      <Alert severity="info" sx={{ mb: 3 }}>
        Important: Once the DD is approved, please upload a clear image of the
        DD along with its number to simplify future follow-up.
      </Alert>
      <Divider sx={{ mb: 3 }} />

      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Controller
              name="date"
              control={control}
              render={({ field }) => (
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    sx={{ width: "100%" }}
                    {...field}
                    label="Date"
                    disabled
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        error={!!errors.date}
                        helperText={errors.date?.message}
                      />
                    )}
                  />
                </LocalizationProvider>
              )}
            />
          </Grid>
          {/* DD Type */}
          <Grid item xs={12} md={6}>
            <FormControl fullWidth error={!!errors.type}>
              <InputLabel>DD Type</InputLabel>
              <Controller
                name="type"
                control={control}
                render={({ field }) => (
                  <Select {...field} label="DD Type">
                    {ddTypes.map((type) => (
                      <MenuItem key={type} value={type}>
                        {type}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
              <FormHelperText>{errors.type?.message}</FormHelperText>
            </FormControl>
          </Grid>

          {/* Amount */}
          <Grid item xs={12} md={6}>
            <Controller
              name="amount"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Amount"
                  fullWidth
                  error={!!errors.amount}
                  helperText={errors.amount?.message}
                  InputProps={{
                    inputComponent: NumberFormatCustom,
                  }}
                />
              )}
            />
          </Grid>

          {/*  */}

          {/* Payment From */}
          <Grid item xs={12} md={6}>
            <FormControl fullWidth error={!!errors.paymentFrom}>
              <InputLabel>Payment From</InputLabel>
              <Controller
                name="paymentFrom"
                control={control}
                render={({ field }) => (
                  <Select {...field} label="Payment From">
                    {paymentFromOptions.map((option) => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
              <FormHelperText>{errors.paymentFrom?.message}</FormHelperText>
            </FormControl>
          </Grid>

          {/* Beneficiary Details Section */}
          <Grid item xs={12}>
            <Typography variant="h6">Account details</Typography>
          </Grid>

          {/* Account Holder Name */}
          <Grid item xs={12} md={6}>
            <Controller
              name="paymentDetails.accountHolderName"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Account Holder Name"
                  fullWidth
                  error={!!errors.paymentDetails?.accountHolderName}
                  helperText={errors.paymentDetails?.accountHolderName?.message}
                />
              )}
            />
          </Grid>

          {/* Bank Name */}
          <Grid item xs={12} md={6}>
            <Controller
              name="paymentDetails.bankName"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Bank Name"
                  fullWidth
                  error={!!errors.paymentDetails?.bankName}
                  helperText={errors.paymentDetails?.bankName?.message}
                />
              )}
            />
          </Grid>

          {/* Account Number */}
          <Grid item xs={12} md={6}>
            <Controller
              name="paymentDetails.accountNumber"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Account Number"
                  fullWidth
                  error={!!errors.paymentDetails?.accountNumber}
                  helperText={errors.paymentDetails?.accountNumber?.message}
                />
              )}
            />
          </Grid>

          {/* IFSC Code */}
          <Grid item xs={12} md={6}>
            <Controller
              name="paymentDetails.ifscCode"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="IFSC Code"
                  fullWidth
                  error={!!errors.paymentDetails?.ifscCode}
                  helperText={errors.paymentDetails?.ifscCode?.message}
                />
              )}
            />
          </Grid>

          {/* Contact Person Details */}
          <Grid item xs={12}>
            <Typography variant="h6">Contact info for this dd</Typography>
          </Grid>

          <Grid item xs={12} md={6}>
            <Controller
              name="contactPerson.company"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Company"
                  fullWidth
                  error={!!errors.contactPerson?.company}
                  helperText={errors.contactPerson?.company?.message}
                />
              )}
            />
          </Grid>

          {/* Contact Person Name */}
          <Grid item xs={12} md={6}>
            <Controller
              name="contactPerson.name"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Name"
                  fullWidth
                  error={!!errors.contactPerson?.name}
                  helperText={errors.contactPerson?.name?.message}
                />
              )}
            />
          </Grid>

          {/* Contact Person Email */}
          <Grid item xs={12} md={6}>
            <Controller
              name="contactPerson.email"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Email"
                  fullWidth
                  error={!!errors.contactPerson?.email}
                  helperText={errors.contactPerson?.email?.message}
                />
              )}
            />
          </Grid>

          {/* Contact Person Phone */}
          <Grid item xs={12} md={6}>
            <Controller
              name="contactPerson.phone"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Phone"
                  fullWidth
                  error={!!errors.contactPerson?.phone}
                  helperText={errors.contactPerson?.phone?.message}
                />
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <UploadFilesImages
              fileUploadData={handleUploadedFiles}
              setIsUploading={setUploadingFile}
              fileUploadType={"ddDocs"}
            />
          </Grid>

          {/* Purpose */}
          <Grid item xs={12}>
            <Controller
              name="purposeOfDD"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Purpose*"
                  fullWidth
                  multiline
                  rows={4}
                  error={!!errors.purposeOfDD}
                  helperText={errors.purposeOfDD?.message}
                />
              )}
            />
          </Grid>

          {/* Submit Button */}
          <Grid item xs={12} sx={{ textAlign: "right", mt: 2 }}>
            <Button
              variant="contained"
              type="submit"
              disabled={loader || uploadingFile}
              startIcon={loader ? <CircularProgress size={20} /> : <SendIcon />}
              size="large"
              style={{ textTransform: "none" }}
            >
              {loader ? "Submitting..." : "Submit request"}
            </Button>
          </Grid>
        </Grid>
      </form>
    </Paper>
  );
}
